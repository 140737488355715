.HeroImage-container-img {
    position: relative;
    max-width: 100vw;
    max-height: 85vh;
}

.Hero-banner-img {
    max-width: 100vw;
    border-radius: 2rem;
}

.HeroImage-img {
    margin: 0;
    position: absolute;
    top: 18%;
    left: 37.5%;
}

.BT-maskot {
    height: 25vw;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .BT-maskot {
      animation: Maskot-spin infinite 20s linear;
    }
  }
  
  @keyframes Maskot-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.HeroImage-design-button {
    min-width: 40vw;
    font-size: 3vw;
    padding: 10px;
    text-decoration: none;
}

.HeroImage-design-button-div {
    position: absolute;
    top: 60%;
    margin: auto;
    width: 100vw;
}

.HeroImage-title-text {
    position: absolute;
    top: 5%;
    margin: auto;
    width: 100vw;
    font-size: 4vmax;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-family: 'Aladin', sans-serif;
}

.HeroImage-title-text-sm {
    position: absolute;
    top: 15%;
    margin: auto;
    width: 100vw;
    font-size: 2.5vmax;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.HeroImage-title-subtext {
    /* color: #318579; */
    color: #38ada6;
    font-size: 1.2vmax;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
}
