.Canvas {
    /* border-style: dashed; */
    border-radius: 10px;
    border-width: thin;
    border-color: rgb(0, 0, 0, 0.3);
    cursor: pointer;
    touch-action: pinch-zoom;
}

.Canvas-men {
    width: 43%;
}

.Canvas-women {
    width: 43%;
}

.Canvas-kids {
    width: 35%;
}

.Canvas-art_portrait {
    width: 80%;
}

.Canvas-art_landscape {
    width: 80%;
}