.flip-container {
    perspective: 1000;
}

.flip-container:hover .img-container {
    transform: rotateY(180deg);
}

.Home-product-img {
    margin: 0rem auto 0.2rem;
    border-radius: 100%;
    max-height: 1000px;
    max-width: auto;
}

.img-container, .before-flip, .after-flip {
    max-width: 40vw;
    min-height: 25vw;
}

.img-container {
    transition: 0.8s ease-out;
    transform-style: preserve-3d;
    position: relative;
}

.before-flip, .after-flip {
    backface-visibility: hidden;
    position: absolute;
    width: 25vw;
}

.before-flip {
    z-index: 2;
    transform: rotateY(0deg);
    top: 0;
    left: 0;
    right: 0;
}

.after-flip {
    z-index: 1;
    transform: rotateY(180deg);
    top: 0;
    left: 0;
    right: 0;
}

.rotate-animate {
    animation: 5s ease-in-out rotateImg;
    animation-iteration-count: infinite;
}

@keyframes rotateImg {
    10% {
        transform: rotateY(-30deg);
    }
    40% {
        transform: rotateY(0deg);
    }
    60% {
        transform: rotateY(-35deg);
    }
}