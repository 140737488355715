.Typewriter-text {
    padding: 15vh 0 0 0;
    font-size: calc(10px + 2vw);
}

.Typewriter-center {
    margin: auto;
    text-align: center;
}

.Typewriter-img {
    margin-top: 5vh;
    width: 30vw;
    border-radius: 2rem;
}

@media (max-width: 992px) {
    .Typewriter-img {
        width: 50vw;
    }
}

.Typewriter-cursor {
    animation: blink 1s linear infinite;
}
@-webkit-keyframes blink {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
