.rotate {
    height: 50px;
    animation: rotate 6s linear infinite;
 }
@keyframes rotate{
   0% {
      transform:rotateY(0deg);
   }
   50% {
      transform:rotateY(180deg);
   }
   51% {
      transform:rotateY(180deg);
   }
   99% {
      transform:rotateY(0deg);
   }
   100% {
      transform:rotateY(0deg);
   }
}

.colorise {
   animation: 10s infinite alternate mycolor;

}
   @keyframes mycolor {
      0% {color: #b38179;}
      10% {color: #faac8a;}
      20% {color: #6c5a89;}
      30% {color: #454545;}
      40% {color: #6c5a89;}
      50% {color: #a890cb;}
      60% {color: #fcd6be;}
      70% {color: #faac8a;}
      80% {color: #b38179;}
      90% {color: #b38179;}
      100% {color: #b38179;}
   }

   @-webkit-keyframes mycolor {
      0% {color: #56756b;}
      10% {color: #89b0a6;}
      20% {color: #88b0d0;}
      30% {color: #557085;}
      40% {color: #6c5a89;}
      50% {color: #a890cb;}
      60% {color: #6c5a89;}
      70% {color: #557085;}
      80% {color: #88b0d0;}
      90% {color: #88b0d0;}
      100% {color: #56756b;}

      /* 0% {color: #efe8c9;}
      10% {color: #469597;}
      20% {color: #5BA199;}
      40% {color: #8d7d3e;}
      60% {color: #c5b471;}
      80% {color: #efe8c9;} */
   }

.media {
   width: 12vw;
}

@media (min-width: 768px) {
   .media {
      width: 5vw;
   }
}
@media (max-width: 768px) {
   .media {
      width: 5vw;
   }
}