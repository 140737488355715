.ProductCard-tabs {
    height: auto;
    border: double 4px rgba(255, 255, 255, 0.4);
    padding: 0rem 1rem;
    color: #ffffff;
    border-radius: 2rem;
    /* min-height: 350px; */
    display: flex;
}

.ProductCard-text {
    text-transform: uppercase;
    color: #d8c05f87;
    font-size: 1em;
}
.ProductCard-text-white {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 1em;
}

.ProductAlert {
    border-radius: 1.5rem;
}