.Gallery-div {
    width: 273px;
    height: 33vh;
    position: relative;
    perspective: 1300px;
    margin: auto;
    margin-bottom: 220px;
}

.Gallery-carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    animation: rotate infinite 40s linear;
}

.Gallery-carousel:hover {
    animation-play-state: paused;
    cursor: pointer;
}

.Gallery-carousel .img:hover {
    transform: scale(1.1);
    transition: all 0.5s;
    cursor: pointer;
}

.Gallery-img {
    position: absolute;
    left: 10px;
    top: 5vh;
    font-size: calc(10px + 1vw);
}

.Gallery-product {
    transform: scale(1.1);
}

.img {
    width: 247px;
    border-radius: 2rem;
}

.Gallery-img:nth-child(1) { transform: rotateY(  0deg) translateZ(340px); }
.Gallery-img:nth-child(2) { transform: rotateY( 45deg) translateZ(340px); }
.Gallery-img:nth-child(3) { transform: rotateY( 90deg) translateZ(340px); }
.Gallery-img:nth-child(4) { transform: rotateY(135deg) translateZ(340px); }
.Gallery-img:nth-child(5) { transform: rotateY(180deg) translateZ(340px); }
.Gallery-img:nth-child(6) { transform: rotateY(225deg) translateZ(340px); }
.Gallery-img:nth-child(7) { transform: rotateY(270deg) translateZ(340px); }
.Gallery-img:nth-child(8) { transform: rotateY(315deg) translateZ(340px); }

@media (max-width: 1200px) {
    .Gallery-div {
        width: 210px;
        height: 30vh;
        position: relative;
        perspective: 1000px;
        margin-bottom: 20vw;
    }
}
@media (max-width: 1200px) {
    .img {
        width: 190px;
    }
}

@media (max-width: 992px) {
    .Gallery-div {
        width: 140px;
        height: 22vh;
        position: relative;
        perspective: 700px;
    }
}
@media (max-width: 992px) {
    .img {
        width: 133px;
    }
}

@media (max-width: 992px) {
    .Gallery-img:nth-child(1) { transform: rotateY(  0deg) translateZ(280px); }
    .Gallery-img:nth-child(2) { transform: rotateY( 45deg) translateZ(280px); }
    .Gallery-img:nth-child(3) { transform: rotateY( 90deg) translateZ(280px); }
    .Gallery-img:nth-child(4) { transform: rotateY(135deg) translateZ(280px); }
    .Gallery-img:nth-child(5) { transform: rotateY(180deg) translateZ(280px); }
    .Gallery-img:nth-child(6) { transform: rotateY(225deg) translateZ(280px); }
    .Gallery-img:nth-child(7) { transform: rotateY(270deg) translateZ(280px); }
    .Gallery-img:nth-child(8) { transform: rotateY(315deg) translateZ(280px); }
}

@media (max-width: 769px) {
    .Gallery-img:nth-child(1) { transform: rotateY(  0deg) translateZ(220px); }
    .Gallery-img:nth-child(2) { transform: rotateY( 45deg) translateZ(220px); }
    .Gallery-img:nth-child(3) { transform: rotateY( 90deg) translateZ(220px); }
    .Gallery-img:nth-child(4) { transform: rotateY(135deg) translateZ(220px); }
    .Gallery-img:nth-child(5) { transform: rotateY(180deg) translateZ(220px); }
    .Gallery-img:nth-child(6) { transform: rotateY(225deg) translateZ(220px); }
    .Gallery-img:nth-child(7) { transform: rotateY(270deg) translateZ(220px); }
    .Gallery-img:nth-child(8) { transform: rotateY(315deg) translateZ(220px); }
}

@media (max-width: 576px) {
    .Gallery-img:nth-child(1) { transform: rotateY(  0deg) translateZ(180px); }
    .Gallery-img:nth-child(2) { transform: rotateY( 45deg) translateZ(180px); }
    .Gallery-img:nth-child(3) { transform: rotateY( 90deg) translateZ(180px); }
    .Gallery-img:nth-child(4) { transform: rotateY(135deg) translateZ(180px); }
    .Gallery-img:nth-child(5) { transform: rotateY(180deg) translateZ(180px); }
    .Gallery-img:nth-child(6) { transform: rotateY(225deg) translateZ(180px); }
    .Gallery-img:nth-child(7) { transform: rotateY(270deg) translateZ(180px); }
    .Gallery-img:nth-child(8) { transform: rotateY(315deg) translateZ(180px); }
}

@media (max-width: 460px) {
    .img {
        width: 100px;
    }
}

@media (max-width: 460px) {
    .Gallery-img:nth-child(1) { transform: rotateY(  0deg) translateZ(120px); }
    .Gallery-img:nth-child(2) { transform: rotateY( 45deg) translateZ(120px); }
    .Gallery-img:nth-child(3) { transform: rotateY( 90deg) translateZ(120px); }
    .Gallery-img:nth-child(4) { transform: rotateY(135deg) translateZ(120px); }
    .Gallery-img:nth-child(5) { transform: rotateY(180deg) translateZ(120px); }
    .Gallery-img:nth-child(6) { transform: rotateY(225deg) translateZ(120px); }
    .Gallery-img:nth-child(7) { transform: rotateY(270deg) translateZ(120px); }
    .Gallery-img:nth-child(8) { transform: rotateY(315deg) translateZ(120px); }
}