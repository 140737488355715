.Footer {
    background-color: #F3CAAE99;
    padding-left: 5vw;
}

.Footer-text {
    font-weight: 100;
    font-size: 1vmax;
    text-align: left;
    letter-spacing: 3px;
    margin: 2%;
}

@media (max-width: 768px) {
    .Footer-text {
        padding: 4%;
        font-size: 1.6vmax;
    }
}