/* tried to add inline style code as class, but wont work */
.GenerateStyle-align {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.GenerateStyle-button {
    max-width: "22vw";
    max-height: "22vw";
    z-Index: "1";
    position: "absolute";
    background-color: transparent;
}
/* tried to add inline style code as class, but wont work */
.GenerateStyle-close-btn {
    cursor: pointer;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0.5%;
    right: 0.5%;
}

.GenerateStyle-overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: #ffe3cf90;
    border-radius: 2rem;
    transition: 1s;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    overflow-x: hidden; /* disable horizontal scroll */
    justify-content: center;
    align-items: center;
}

.GenerateStyle-overlay-content {
    position: relative;
    width: 90vw;
    top: 14%;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 2rem;
    padding: 3%;
    border: 4px double rgba(255, 255, 255, 0.4);
}

.GenerateStyle-input {
    border-radius: 1rem;
    padding: 2% 3%;
    min-height: 8vh;
    width: 70vw;
    text-align: center;
    font-size: 1.5vmax;
    color: white;
}

@media (min-width: 992px) {
    .GenerateStyle-input {
        min-height: 8vh;
    }
}

.GenerateStyle-styles {
    border-radius: 1rem;
    max-width: 80%;
}

.GenerateStyle-caption {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 1rem;
    color: #454545;
    font-size: calc(10px + 1vmin);
}

.GenerateStyle-text {
    text-transform: uppercase;
    font-size: 1.5vw;
    font-weight: 600;
    background: linear-gradient(90deg,
        #efe8c9,
        #469597 10%,
        #469597 20%,
        #5BA199 30%,
        #5BA199 40%,
        #8d7d3e 50%,
        #8d7d3e 60%,
        #c5b471 70%,
        #c5b471 80%,
        #efe8c9 90%,
        #efe8c9 100%
        );

    background-size: 200% auto;
    /* color: #000; */
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: colorise 10s alternate infinite;
}
@keyframes colorise {
    to {
        background-position: 200% center;
    }
}

@media (min-width: 992px) {
    .GenerateStyle-text {
        font-size: 1vw;
    }
}

.carousel-control-next-icon {
    animation: bounceRight 3s ease-in-out infinite;
    /* animation-iteration-count: 2; */
}

@keyframes bounceRight {
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
    }
    40% {
        transform: translateX(15px);
    }
    60% {
        transform: translateX(8px);
    }
}

.carousel-control-prev {
    animation: bounceLeft 3s ease-in-out infinite;
    /* animation-iteration-count: 2; */
}

@keyframes bounceLeft {
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
    }
    40% {
        transform: translateX(-15px);
    }
    60% {
        transform: translateX(-8px);
    }
}

.GenerateStyle-prompts {
    font-size: calc(5px + 0.8vw);
    border-radius: 25px;
    border: 3px #d8c05f87 double;
    /* font-weight: 100; */
    text-transform: uppercase;
    padding: 10px;
    text-decoration: none;
    height: calc(35px + 10vh);
    width: 25vw;
  }

  .GenerateStyle-prompts:hover {
    border-radius: 2rem;
    border: 3px #d8c05f87 outset;
    background-color: #ffe6d485;
  }