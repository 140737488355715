/* .App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App {
  text-align: center;
  background-color: #FFF8F3;
  color: #454545;
  min-height: 100vh;
  /* overflow: hidden; */
}

.App-text {
  font-size: 1.2vmax;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 5%;
}

body {
  font-family: 'Aboreto', 'Aladin', sans-serif;
}

.buttonStyle-gold {
  color:#c5b471;
  border-radius: 25px;
  border: 3px #d8c05f87 double;
  font-weight: 100;
  text-transform: uppercase;
  padding: 10px;
  text-decoration: none;
}

.buttonStyle-gold:hover {
  border-radius: 2rem;
  border: 3px #d8c05f87 outset;
  background-color: #ffe6d485;
}

.buttonStyle-black {
  color: #454545;
  border-radius: 25px;
  border: 3px #00000087 double;
  font-weight: 100;
  text-transform: uppercase;
  padding: 10px;
  text-decoration: none;
}

.buttonStyle-black:hover {
  border-radius: 2rem;
  border: 3px #d8c05f87 outset;
  background-color: #ffe6d485;
}

.buttonStyle-white {
  color: white;
  border-radius: 25px;
  border: 3px #ffffff87 double;
  font-weight: 100;
  text-transform: uppercase;
  padding: 10px;
  text-decoration: none;
}

.buttonStyle-white:hover {
  border-radius: 2rem;
  border: 3px #d8c05f87 outset;
  background-color: #ffe6d485;
}

.buttonStyle-red {
  color: white;
  border-radius: 25px;
  border: 3px #7d191987 double;
  font-weight: 100;
  text-transform: uppercase;
  padding: 10px;
  text-decoration: none;
}

.buttonStyle-red:hover {
  border-radius: 2rem;
  border: 3px #7d191987 outset;
  background-color: #ffe6d485;
}

.buttonAnimate {
  background-color: #ffe6d485;
  color: #454545;
  min-width: 40%;
  font-size: 2vmax;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  border: 3px #ffffff double;
  cursor: pointer;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  animation: glowing 3500ms infinite;
  font-weight: 100;
  text-transform: uppercase;
  text-decoration: none;
}

.buttonAnimate-sm {
  background-color: transparent;
  color: #454545;
  min-width: 15%;
  font-size: 2vmax;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  border: 3px #ffffff double;
  cursor: pointer;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  animation: glowing 3500ms infinite;
  font-weight: 100;
  text-transform: uppercase;
  text-decoration: none;
}

.buttonAnimate:hover {
  border: 2px #c5b471 outset;
  color: #454545;
  background-color: #ffe6d485;
}

.buttonAnimate:focus {
  border: 2px rgb(197, 180, 113) outset;
}

@keyframes glowing {
  0% {
      box-shadow: 0 0 8px #c6968a;
  }

  50% {
      box-shadow: 0 0 25px #faac8a;
  }

  100% {
      box-shadow: 0 0 8px #c6968a;
  }
}

.colors {
  color: #38ada6;
  color: #c5b471;
}

.cursorPointer {
  cursor: pointer;
}

img.hover-zoom {
  transition: all 0.3s ease 0s;
}

img.hover-zoom-light {
  transition: all 0.3s ease 0s;
}

img.hover-zoom:hover {
  transform: scale(1.15);
}

img.hover-zoom-light:hover {
  transform: scale(1.05);
}

.logo {
  margin: 15px;
  height: 5vh;
}

.pageHeight {
  min-height: 60vh;
}

.toastStyle-black {
  color:#000000;
  background-color: white;
  border-radius: 25px;
  border: 3px #00000087 double;
}

.toastStyle-gold {
  color:#c5b471;
  background-color: white;
  border-radius: 25px;
  border: 3px #d8c05f87 double;
}

/* CSS style for scroll bars on this app */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 2rem;
}

::-webkit-scrollbar-thumb {
  border: 1px solid #d8c05f87;
  background-color:#d8c05f30;
  border-radius: 2rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color:#c5b471;
}

/* Allows to let text scroll from side to side */
/* .Text-move {
  position: absolute;
  white-space: nowrap;
  animation: textMove 10s linear alternate infinite;
}

@keyframes textMove {
  0% {left: 15vw; color: #c5b471;}
  33% {left: 30vw; color: #cbc093;}
  66% {left: 45vw; color: #ffffff;}
  100% {left: 60vw; color: #e3dcc3;}
} */

/* creates a linear color gradient */
.background-gradient-1 {
  background: linear-gradient(120deg, #000000 10%, #4a5a67 45%, rgba(0,0,255,0) 80%);
  border-radius: 1rem;
}
.background-gradient-2 {
  background: linear-gradient(120deg, #88b0d0 10%, #4a5a67 25%, rgba(0,0,255,0) 45%);
  border-radius: 1rem;
}
