.LoaderContainer {
    height: 15px;
    width: 20vw;
    padding: 3px;
    border: solid 1px;
    color: #c5b471;
    background-color: transparent;
    border-radius: 2rem;
    display: inline-block;
    margin-top: 5px;
}

@media (max-width: 767px) {
    .LoaderContainer {
        width: 40vw;
    }
}

.LoaderFillerStyle {
    height: 100%;
    background-color: #c5b47187;
    border-radius: inherit;
    text-align: right;
    transition: width 3s ease-out;
}

.LoaderLabelStyle {
    padding-right: 5px;
    font-size: 2.5vw;
    color: white;
    font-weight: 600;
}