.ReturnModal-h1 {
    font-weight: 400;
    font-size: 1.6vmax;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.ReturnModal-h2 {
    font-weight: 600;
    color: #ac8f1a;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
}

.ReturnsModal-text {
    font-size: 1vmax;
}