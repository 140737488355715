.TeemillButton-Canvas {
    display: none;
}

.TeemillButton-bounce {
    animation: bounceUp 3s ease-in-out;
    animation-iteration-count: 2;
}

@keyframes bounceUp {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-15px);
    }
    60% {
        transform: translateY(-8px);
    }
}

@media (max-width: 768px) {
    .ImageUploadCloud {
        animation: none;
    }
 }