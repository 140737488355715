.image {
    width: 10vw;
    margin: 1px;
    border-radius: 0.5rem;
    position: relative;
}

@media (max-width: 768px) {
    .image {
        width: 12vw;
    }
}

.layout {
    display: inline-block;
    margin: 1vw;
}

.text {
    text-transform: uppercase;
    color: #d8c05f87;
    margin: 5px;
    border: none;
    font-size: 1vmax;
}

.radioHidden {
    position: absolute;
    opacity: 0;
    width: 0;
}

.radioHidden + .image {
    cursor: pointer;
}

.radioHidden + .image:hover {
    border-radius: 0.5rem;
    padding: 0.2vw;
    border: 2px #d8c05f87 double;
}

.radioHidden:checked + .image {
    border-radius: 0.5rem;
    padding: 0.2vw;
    box-shadow: 3px 2px 10px #d8c05f87;
    border: 2px #c5b471 outset;
}

