.Navigation {
    position: sticky;
    position: -webkit-sticky;
    background-color: #ffe6d4;
    top: 0;
    padding-left: 10vw;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

@media (max-width: 991px) {
    .NavHide {
        display: none;
    }
}
@media (min-width: 991px) {
    .NavHideHome {
        display: none;
    }
}

.menuItem {
    padding: 10px;
    margin: 15px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: calc(10px + 0.6vw);
    color: #454545;
}

/* .menuItem:hover {
    background-color: #f7ece2;
} */

.Navigation-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Navigation-tagnav {
    justify-content: center;
    margin-right: 25px;
    font-size: calc(10px + 0.8vmin);
}

.Navigation-tagline {
    margin-top: 10px;
    margin-right: 25px;
    font-size: calc(10px + 1.2vmin);
}

/* .Navigation-toggle {
    padding: 0 4px 0 4px;
    color:#454545;
    border-radius: 25px;
    border: 3px #d8c05f87 double;
} */