.Stylize-image {
    max-width: 70vw;
    max-height: 50vh;
}

.Stylize-tabs {
    width: 90%;
    height: auto;
    margin: 0.5rem auto 1.5rem;
    padding: 2rem 1rem;
    border-radius: 2rem;
    /* border: double 4px #d8c05f30; */
    /* background-color: #f3caae3a; */
}

.Stylize-tabs-img {
    margin: 2.5rem auto 1.5rem;
    border-radius: 1rem;
}

.Stylize-nav {
    width: 95%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #d8c05f87;
    border-radius: 2rem;
    /* padding-left: 0px; */
    padding: 2px;
}

@media (max-width: 768px) {
    .Stylize-nav {
        width: 100%;
    }
    .Stylize-tabs {
        width: 95%;
    }
}

.Stylize-nav li {
    font-size: 1.2vmax;
    width: 30%;
    padding: 5px;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-radius: 2rem;
}

.Stylize-nav li:hover {
    border-radius: 200px;
    border: 1px #d8c05f87 solid;
}

.Stylize-nav li.active {
    border-radius: 200px;
    border: 2px #c5b471 outset;
}

.Stylize-nav li.disabled {
    border: none;
    cursor: default;
}

.Stylize-myDesign {
    display: inline-block;
}