.loading {
    text-align: center;
}

.Painting-text {
    text-transform: uppercase;
    font-family: 'Aladin', sans-serif;
    font-weight: 600;
}

.loading span {
    display: inline-block;
    margin: 0 0.1em;
    font-size: 3vmax;
}

.loading01 span {
    animation: loading01 1.4s infinite alternate;
}
.loading01 span:nth-child(1) {
    animation-delay: 0s;
}
.loading01 span:nth-child(2) {
    animation-delay: 0.1s;
}
.loading01 span:nth-child(3) {
    animation-delay: 0.2s;
}
.loading01 span:nth-child(4) {
    animation-delay: 0.3s;
}
.loading01 span:nth-child(5) {
    animation-delay: 0.4s;
}
.loading01 span:nth-child(6) {
    animation-delay: 0.5s;
}
.loading01 span:nth-child(7) {
    animation-delay: 0.6s;
}
.loading01 span:nth-child(8) {
    animation-delay: 0.7s;
}
@keyframes loading01 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
   }
}