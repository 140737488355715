.Home-background-color-m {
    background-color: rgba(0, 0, 0, 0.5);
    margin: 10%;
    padding: 2% 4%;
    border-radius: 2rem;
}

.HomeSpinner {
    animation-duration: 3s;
    width: 40px;
    height: 40px;
    color: #96f8f250;
    border: 4px #ffffff double;
    margin-top: 25vh;
}

.Home-column-height-s {
    height: 40vh;
}

.Home-column-height-m {
    height: 60vh;
}

.Home-column-height-l {
    height: 80vh;
}

.Home-img {
    margin: 0rem auto 0.2rem;
    border-radius: 1rem;
    max-height: auto;
    max-width: 70vw;
}

.Home-design-button {
    max-width: 60vw;
    font-size: 5vw;
}

.Home-title-headline {
    font-size: clamp(5vw, 7vw, 7vw);
}

.Home-title {
    text-transform: uppercase;
    font-family: 'Aladin', sans-serif;
    font-weight: 600;
    background: linear-gradient(90deg,
        #b38179,
        #c6968a 10%,
        #faac8a 20%,
        #6c5a89 30%,
        #454545 40%,
        #6c5a89 50%,
        #a890cb 60%,
        #fcd6be 70%,
        #faac8a 80%,
        #c6968a 90%,
        #b38179 100%);

    background-size: 200% auto;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: colorise 10s alternate infinite;
}
    @keyframes colorise {
        to {
            background-position: 200% center;
        }
    }

.Home-title-text {
    font-size: 2vmax;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 5px;
}
.Home-title-text-sm {
    font-size: 1.5vmax;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.Home-title-subtext {
    color: #38ada6;
    font-size: 1.2vmax;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.Home-text {
    font-size: 1.2vmax;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin: 2%;
}

#HomeSection1 {
    background-size: cover;
    /* background-size: contain; */
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-attachment:local;
    background-position: center;
    height: 40vh; /* 100% of viewport height */
    margin: 0 auto;
    position: relative;
}

#HomeSection2 {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    margin: 0 auto;
    border-radius: 2rem;
    margin: 5%;
}

@media (max-width: 768px) {
    #HomeSection2 {
        background-size: cover;
        background-attachment:local;
    }
}

#HomeSection2-local {
    background-size:cover;
    background-repeat: no-repeat;
    background-attachment:local;
    background-position: center;
    /* height: 50vh; */
    max-height: 768px;
    max-width: 1024px;
    margin: 0 auto;
    position: relative;
    border-radius: 2rem;
}

#HomeSection3 {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    height: 80vh; /* 100% of viewport height */
    margin: 0 auto;
    position: relative;
}

.Home-gallery {
    /* background: black; */
    overflow: hidden;
    position: relative;
    margin: 5%;
}

.Home-gallery-img {
    object-fit: cover;
    opacity: 0.6;
    padding: 5px;
}

.Home-gallery-text {
    margin: -10% 0%;
    width: 200px;
    height: 50px;
    padding: 10px 5px;
    position: absolute;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: #c5b471;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 1.2vw;
    border-radius: 2rem;
}

@media (max-width: 768px) {
    .Home-gallery-text {
        font-size: 2vw;
    }
}

.Home-gallery-products {
    margin: 0 auto;
}
@media (min-width: 992px) {
    .Home-gallery-products {
        width: 90%;
        margin: 0 auto;
    }
}

.Home-tabs-overlay {
    width: 100%;
    color: #000000;
    border-radius: 2rem;
    border: double rgba(255, 255, 255, 0.4);
}

.Home-tabs {
    width: 90%;
    height: auto;
    min-height: 200px;
    margin: 1.5rem auto;
    padding: 0rem 2rem;
    color: #454545;
    border-radius: 2rem;
    border: double 4px #d8c05f30;
}